<template>
  <div class="swiper">
    <div class="swiper-container">
      <div class="swiper-wrapper">
        <div class="swiper-slide" v-for="(item, index) in list" :key="index">
          <div class="swiper-wp">
            <img :src="item.imageUrl" alt="" />
            <div class="content">
              <h2>{{ item.name }}</h2>
              <p v-html="item.description"></p>
            </div>
          </div>
        </div>
      </div>
      <!-- 如果需要分页器 -->
      <div class="swiper-pagination"></div>

      <!-- 如果需要导航按钮 -->
      <div class="swiper-button-prev swiper-button-white"></div>
      <div class="swiper-button-next swiper-button-white"></div>

      <!-- 如果需要滚动条 -->
      <!--    <div class="swiper-scrollbar"></div>-->
    </div>
  </div>
</template>
    
<script>
export default {
  name: "swiper",
  data() {
    return {};
  },
  props: ["list"],
  methods: {},
  mounted() {},
  watch: {
    list(value) {
      if (this.$store.state.swiper) {
        this.$store.state.swiper.destroy();
      }
      setTimeout(() => {
        this.$store.state.swiper = new this.swiper(".swiper-container", {
          loop: true,
          speed: 700,
          // 如果需要分页器
          pagination: ".swiper-pagination",
          paginationClickable: true,
          // 如果需要前进后退按钮
          nextButton: ".swiper-button-next",
          prevButton: ".swiper-button-prev",
          // 如果需要滚动条
          // scrollbar: '.swiper-scrollbar',
          //如果需要自动切换海报
          autoplay: 3000,
        });
      }, 500);

      // this.$store.state.swiper.update();
    },
  },
};
</script>
    
<style scoped>
.swiper {
  background: #edf0f0;
}
.swiper-container {
  height: 100vh;
  width: 100%;
}
.swiper-wrapper .swiper-slide {
  width: 100%;
  height: 100%;
  text-align: center;
  overflow: hidden;
}
.swiper-slide img {
  /* width: 100%; */
  height: 100%;
}
.swiper-wp {
  width: 100%;
  height: 100%;
  position: relative;
}
.swiper-wp .content {
  width: 25%;
  padding: 20px;
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  right: 10%;
  transform: translateY(-50%);
  /* height: 100%; */
  color: #000;
  font-size: 20px;
  background: rgba(255, 255, 255, 0.5);
  border-radius: 10px;
}
.swiper-wp .content h2 {
  text-align: center;
}
.swiper-wp .content p {
  margin-top: 20px;
  text-align: start;
}
@media screen and (max-width: 768px) {
  .swiper-container {
    height: auto;
    width: 100%;
  }

  .swiper-slide img {
    width: 100%;
    height: 100%;
  }
  .swiper-wp .content {
    width: 100%;
    padding: 20px;
    box-sizing: border-box;
    position: absolute;
    top: auto;
    bottom: 0;
    right: 0%;
    transform: translateY(0);
    /* height: 100%; */
    color: #000;
    font-size: 20px;
    background: rgba(255, 255, 255, 0.5);
    border-radius: 10px;
  }
  .swiper-wp .content h2 {
    font-size: 18px;
    text-align: center;
  }
  .swiper-wp .content p {
    margin-top: 16px;
    text-align: start;
  }
}
</style>
<style>
.swiper-pagination-bullet {
  width: 12px;
  height: 12px;
}
.swiper-pagination-bullet-active {
  background: #fff;
}
</style>